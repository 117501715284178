.rx-progress-bar {
&.no-top-margin {
    line-height: 0;
  }

  &.flat-ends {
    .ant-progress-inner {
      border-radius: 0;
    }
  }
}
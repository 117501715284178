@import 'src/styles/media-breakpoint';
@import 'src/styles/palette';

.legend-outer-container {
  position: relative;
}
.legend-container {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-items: flex-end;
  padding: 15px;
}
.item-container {
  display: inline-block;
}
.item-color {
  color: transparent;
  width: 25px;
}
.item-label {
  font-size: 0.8em;
  font-weight: normal;
  padding-left: 16px;
  padding-right: 16px;
}

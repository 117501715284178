.symptoms-trend {
  height: 100px;
  margin: 20px 0;

  &__title {
    color: #60bb46;
    font-weight: 600;
    line-height: 22px;
    padding-left: 10px;
  }
}

@import 'src/styles/palette';

.form {
  &-footer {
    align-items: center;
    background: linear-gradient(
      180deg,
      color(background, dark) 1.33%,
      color-alpha(background, dark, 0.29) 100%
    );
    border-radius: 10px;
    display: flex;
    justify-content: flex-end;
    padding: 32px;
    margin-top: 32px;

    &__text {
      color: color(foreground, light);
      font-size: 14px;
      margin-right: 24px;
      text-align: right;

      &-label {
        font-size: 12px;
        text-transform: uppercase;
      }
    }

    &__submit-btn {
      display: flex;
      align-items: center;
    }
  }
}

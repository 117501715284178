@import 'src/styles/variables';

.crx-rating {
  &__input {
    width: 80px;
  }
}

/* Ant design slider dot customization */

.ant-slider-handle::before {
  border-radius: 50%;
  box-shadow: 0 0 0 4px rgba(163, 102, 252, 0.25) !important;
}
.ant-slider:hover .ant-slider-handle::after,
.ant-slider-handle:focus::after {
  background-color: rgba(35, 0, 86, 1) !important;
  border-radius: 50%;
  box-shadow: 0 0 0 4px rgba(35, 0, 86, 1) !important;
}
/* for background color after select */
.ant-slider .ant-slider-handle::after {
  background-color: rgba(35, 0, 86, 1) !important;
}
/* End Ant design slider dot customization */

.crx-circular-beforeLabel {
  font-family: $basefont;
  padding: 0px 20px 0px 20px;
}
.crx-circular-afterLabel {
  font-family: $basefont;
  padding: 0px 20px 0px 20px;
}

.custom-radio .ant-radio-checked .ant-radio-inner .ant-radio-dot {
  display: none;
  opacity: 0;
}

.custom-radio {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}
.custom-radio .ant-radio-inner {
  border-radius: 50%;
  box-shadow: 0 0 0 3px grey;
  cursor: pointer;
  height: 40px;
  left: 4px; /* center offset */
  transition: background 0.3s, color 0.3s;
  width: 40px;
}

.custom-radio.crx-warning .ant-radio-checked .ant-radio-inner,
.custom-radio:hover.crx-warning .ant-radio-inner {
  background: $crx-radio-warning;
}
.custom-radio.crx-yellow .ant-radio-checked .ant-radio-inner,
.custom-radio:hover.crx-yellow .ant-radio-inner {
  background: $crx-radio-yellow;
}
.custom-radio.crx-orange .ant-radio-checked .ant-radio-inner,
.custom-radio:hover.crx-orange .ant-radio-inner {
  background: $crx-radio-orange;
}
.custom-radio.crx-orange-light .ant-radio-checked .ant-radio-inner,
.custom-radio:hover.crx-orange-light .ant-radio-inner {
  background: $crx-radio-orange-light;
}
.custom-radio.crx-green .ant-radio-checked .ant-radio-inner,
.custom-radio:hover.crx-green .ant-radio-inner {
  background: $crx-radio-green;
}
@import 'src/styles/media-breakpoint';

.crx-tile {
  height: 100%;

  &__title {
    color: #242a2d;
    font-size: 14px;
    font-weight: bold;
    line-height: 28px;
    margin-top: 12px;
    text-align: center;
  }

  @include media-breakpoint(gt-md) {
    &__title {
      font-size: 20px;
    }
  }

  .ant-card-body {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-align: center;
  }
}

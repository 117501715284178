.root {
  .ant-picker,
  .ant-select .ant-select-selector {
    border-radius: 4px;
  }

  .ant-form {
    label {
      font-weight: 600;
    }
  }

  .ant-btn,
  .back-button {
    align-items: center;
    display: flex;
    justify-content: center;

    .anticon {
      display: flex;
    }
  }
}

@import 'src/styles/media-breakpoint';
@import 'src/styles/palette';

.patient-dashboard {
  &__title {
    color: color(foreground, light);
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  &__dials {
    align-items: stretch;
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);
    justify-items: stretch;
    margin-bottom: 32px;
  }
  &__legend {
    border: 1px solid blue;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
}

@include media-breakpoint(gt-md) {
  .patient-dashboard {
    &__dials {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}

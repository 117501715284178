@import 'src/styles/media-breakpoint';

.steps-container {
  gap: 24px;
  min-height: 58px;

  &__title {
    line-height: 31px;
    margin: 0px !important;
  }
  &__description {
    line-height: 23px;
    margin: 0px !important;
  }
  &__duration {
    color: #767679;
    margin-left: 8px;
  }
}

.steps-icon-container {
  align-items: center;
  background-color: rgba(163, 102, 252, 0.25);
  border-radius: 26px;
  display: flex;
  height: 52px;
  justify-content: center;
  text-align: center;
  width: 52px;

  &__index {
    /*p element needs to override base margin padding*/
    color: black;
    font-size: 25px;
    margin: 0px;
    padding: 0px;
    /*border: 1px solid blue;*/
  }
}

/* line */
.line-vertical-container {
  box-sizing: border-box;
  height: 58px;
  margin-left: 29px;
}

.line-vertical {
  border-bottom: 1px dashed #230056;
  margin-bottom: 8px;
  margin-top: 8px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  width: 35px;
}

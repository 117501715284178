.layout-container {
  background-color: white;
  padding: 0px;
}
.completion-title {
  color: #242425;
  font-family: Comfortaa;
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 54px;
  text-align: left;
}
.completion-description {
  color: #242425;
  font-family: Comfortaa;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 23px;
  text-align: left;
}
.completion-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: calc(100dvh - 84px - 100px);
  object-fit: contain;
  width: 90%;
}

// calc minus header 84px + footer 100px

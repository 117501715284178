.crx-slider-list {
  .ant-list-item {
    padding: 8px 24px;

    &:hover {
      background: #ffffff;
      border-color: transparent;
      box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.05),
        0px 10px 20px rgba(0, 0, 0, 0.05);
    }
    &:hover {
      border-color: transparent;
    }
  }

  &__item {
    &-title {
      align-self: center;
      color: #5a5e60;
    }

    .ant-slider {
      &:hover .ant-slider-track,
      .ant-slider-track {
        background: transparent;
      }

      .ant-slider-track {
        height: 8px;
        top: 2px;
      }

      .ant-slider-step {
        height: 10px;
        top: 1px;
        z-index: 1000;

        &::before {
          background-color: #ffffff;
          border: 2px solid #61bb46;
          border-radius: 6px;
          content: ' ';
          display: inline-block;
          height: 12px;
          left: -8px;
          position: relative;
          top: -5px;
          width: 12px;
          z-index: 1000;
        }

        &::after {
          background-color: #ffffff;
          border: 2px solid #dae1d6;
          border-radius: 6px;
          content: ' ';
          display: inline-block;
          height: 12px;
          margin-left: 100%;
          position: relative;
          right: 4px;
          top: -27px;
          width: 12px;
          z-index: 1000;
        }
      }

      .ant-slider-handle {
        height: 18px;
        margin-top: -7px;
        width: 18px;
        z-index: 1000;
      }

      .ant-slider-rail {
        background-image: linear-gradient(
          90deg,
          #61bb46 0%,
          #bdce55 8.33%,
          #ffdc5f 91.67%,
          #fa6d53 100%
        );
        height: 8px;
        top: 2px;
      }
    }
  }

  &__shade {
    background-color: #eef0ed;
    height: 8px;
    left: auto;
    position: absolute;
    right: 0;
    top: 12px;
    z-index: 500;
  }
}

.crx-slider-list__item.crx-slider-list__item_inverted {
  .ant-slider {
    .ant-slider-rail {
      background-image: linear-gradient(
        90deg,
        #fa6d53 0%,
        #ffdc5f 8.33%,
        #bdce55 91.67%,
        #61bb46 100%
      );
    }
  }
}

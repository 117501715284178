@import 'src/styles/media-breakpoint';

.symptom-list {
  padding: 20px;

  &__item {
    border-bottom: 1px solid rgba(36, 42, 45, 0.1);
    margin-bottom: 20px;

    &-label,
    &-bar {
      padding: 8px 0;
    }

    &-label {
      align-items: center;
      display: flex;
    }
  }

  &__item:last-child {
    margin-bottom: 0;
  }
}

@include media-breakpoint(gt-md) {
  .symptom-list__item {
    border-bottom: none;

    &-label {
      padding: 0 2px 0 0;
    }

    &-bar {
      padding: 0 7px;
    }
  }
}

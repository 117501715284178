// width value of a page displaying on a desktop device
$desktop-min-width: 1200px;

// gap value between elements on desktop
$desktop-gap: 24px;

// gap value between elements on mobile
$mobile-gap: 16px;

$header-height: 56px;
$header-logo-margin: 56px;

$border-radius-base: 6px;

$basefont: Comfortaa;

$brand: #230056;
$danger: #fc615b;
$dark: #242425;

// radio color buttons inner select / hover in order of severity
$crx-radio-warning: #d72721;
$crx-radio-orange: #e67c12;
$crx-radio-orange-light: #f1bc00;
$crx-radio-yellow: #f1f124;
$crx-radio-green: #3adb66;

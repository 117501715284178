.crx-dial {
  &__title {
    color: #242a2d;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0;
    text-align: center;
  }

  .gauge {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.pdf-container {
  background: white;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-family: 'Comfortaa';
  height: 100vh;
  max-height: 2480px;
  max-width: 3508px;
  padding: 33px 31px 33px 31px;
  width: 760px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 13px;
}

.content-container {
  font-weight: bold;
  padding: 20px 0px 20px 0px;
}

.signature-date-row {
  font-family: Comfortaa;
  gap: 8px;
}
.name-age-row {
  gap: 8px;
  justify-content: space-between;
  margin-bottom: 46px;
}
.name-row {
  display: flex;
  flex: 1;
}
.age-row {
  display: flex;
  flex: 1;
}

.p-info > p {
  margin: 0;
  padding: 0;
}
.p-info span {
  font-family: Comfortaa;
}
.no-underline {
  text-decoration: none;
}

.underline {
  font-size: 16px;
  text-decoration: underline;
  text-underline-position: under;
}

.treatment-ul {
  list-style-type: disc;
}

.img-container {
  align-items: flex-end !important;
  display: flex;
  flex-direction: row;
}
/* .fax-img-row {min-width: 100%; display: flex; flex-direction: row; justify-content: space-between; align-items: center; } */

.prescription-title { 
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 30px;
  padding: 10px 0px 10px 0px;
}

.border-line {
  border-bottom: 3px solid rgba(36, 36, 37, 1);
  margin: 0px;
  padding: 0px;
}

.pdf-footer {
  font-family: Comfortaa;
  margin-bottom: 16px;
  margin-top: auto; // makes footer stick to bottom without position absolute;
}
.doctor-signature {
  font-size: 12px;
}
.doctor-sign-date {
  font-size: 12px;
}

.column1 {
  color: #ff0000;
}

.customFontText {
  font-family: 'Anton';
  font-style: 'normal';
  font-weight: 'normal';
}

.indicator {
  color: #ff0000;
  font-size: 2rem;
  font-weight: 700;
}

.relativeCSS {
  border: solid 1px #ff0000;
}

.testClass {
  background: rgba(76, 175, 80, 0.3);
}

@media (max-width: 600px) {
  .testClass {
    color: rebeccapurple;
  }
  .pdf-container {
    max-width: 90%;
  }
}

.test-border {
  border: 1px solid red;
}
.print-button {
  margin-bottom: 16px;
}

@media print {
  @page { size: portrait; }
  .page-break {
    page-break-before: always;
  }
}
@import 'src/styles/palette';
@import 'src/styles/variables';

.sign-out-button {
  align-items: center;
  background-color: color(background, dark);
  border: none;
  border-radius: 0px 0px 0px 6px;
  display: flex;
  font-size: 13px;
  font-weight: bold;
  height: $header-height;
  justify-content: space-between;
  padding: 10px 14px 10px 14px;
  width: 100px;

  &__rounded {
    border-radius: 20px;
    height: 35px;
    min-width: 100px;
  }
  &__basefont {
    font-family: $basefont;
  }
  &__bigpadding {
    padding: 19px 40px 19px 40px;
  }
}

.dark-button {
  background-color: $dark;
  color: white;
  font-family: $basefont;

  &:hover:not([disabled]) {
    background-color: $dark;
  }

  &__disabled {
    background-color: transparent;
  }
}

/*
  color: color(elevated, base);  
  
*/

/* overrides checkbox styling from ant-design to align checkbox properly */
.account_form_item {
  background-color: #f0f1f5;
  border-color: #767679;
  border-width: 1.5px;
  font-size: 15px;
  font-weight: bold;

  &::placeholder {
    color: #767679;
  }

  & .ant-input {
    &::placeholder {
      color: #767679;
      font-weight: bold;
    }
  }
}

.ant-checkbox {
  align-self: self-start;
  margin-top: 4px;

  .ant-checkbox-inner {
  border-color: #767679;
  border-width: 1.5px;
  margin-right: 5px;
  }
}

.checkbox-consent-group {
  color: black;
  display: flex;
  gap: 4px;
}

.correct-validation {
  color: green;
  gap: 5px;
}
.incorrect-validation {
  color: red;
  gap: 5px;
}

.validation-icon {
  margin-right: 5px;
}

.symptom-label {
  align-items: center;
  display: flex;
  height: 32px;

  &__icon {
    background-color: #60bb46;
    border-radius: 2px;
    color: #fff;
    display: inline-block;
    font-size: 20px;
    height: 32px;
    line-height: 34px;
    width: 32px;

    &_normal {
      background-color: #60bb46;
    }

    &_alert {
      background-color: #ffdc5f;
      color: initial;
    }

    &_warning {
      background-color: #ffa500;
    }

    &_critical {
      background-color: #fb4337;
    }
  }

  &__title {
    color: initial;
    font-weight: 600;
    padding-left: 10px;
  }
}

.crx-yes-no-switch {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  display: flex;
  min-width: 17em;

  &__label {
    border: none;
    color: #b5b5b5;
    flex: 1;

    &:hover {
      border: none;
    }
  }

  &__label.disabled {
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }

  &__button-group {
    .ant-radio-button-wrapper {
      border: none;
      border-left: 1px solid #d9d9d9;
    }
  }
}

.profile-form {
  .ant-picker,
  .ant-select {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    height: 100%;

    &-selection-item {
      overflow: visible;
      white-space: normal;
    }

    &-selection-placeholder {
      color: #767679;
    }

    &-input {
      font-weight: bold;
    }
  }

  &__done-button {
    height: 50px;
    top: -24px;
  }

  &__next-button {
    width: 100px;
  }
}

.form_item {
  border-color: #767679;
  border-width: 1.5px;
  font-size: 15px;
  font-weight: bold;
  padding-bottom: 20px;
}
.symptom-bar {
  height: 32px;
  canvas {
    cursor: pointer !important;
  }

  &_noclick {
    canvas {
      cursor: unset !important;
    }
  }
}

@import 'src/styles/media-breakpoint';

.cgs-page {
  .crx-card {
    .crx-slider-list {
      .ant-list-items {
        padding: 12px 0;
      }
      .crx-slider-list__item .crx-slider-list__item-title {
        font-size: 14px;
      }
    }
  }

  &__done-button {
    height: 50px;
    margin-top: 16px;
    width: 100%;
  }
}

@include media-breakpoint(gt-md) {
  .cgs-page .crx-card {
    .crx-slider-list {
      .ant-list-items {
        padding: 0;
      }
      .crx-slider-list__item .crx-slider-list__item-title {
        font-size: 18px;
      }
    }
  }
}

.historical-points {
  background: linear-gradient(
    180deg,
    rgba(240, 242, 245, 0.32) 0%,
    rgba(240, 242, 245, 0) 100%
  );
  height: 60px;
  position: relative;

  &__expand {
    background-color: white;
    border: 1px solid #e4e6eb;
    border-radius: 14px;
    cursor: pointer;
    display: inline-block;
    font-family: 'Material Icons';
    font-size: 17px;
    height: 28px;
    line-height: 27px;
    position: absolute;
    right: 8px;
    text-align: center;
    top: -14px;
    vertical-align: middle;
    width: 28px;
    z-index: 1000000;
  }

  &_expanded {
    .historical-points__expand {
      content: 'unfold_less';
      top: -40px;
    }
  }

  &__cover {
    display: flex;
    opacity: 0;
    position: absolute;
    transition: visibility 0s, opacity 0.2s linear;
    visibility: hidden;
  }

  &:hover {
    .historical-points__expand {
      background-color: #5278d9;
      border: 1px solid white;
      color: white;
    }

    .historical-points__cover {
      align-items: center;
      background-color: #5278d91a;
      cursor: pointer;
      height: 100%;
      justify-content: center;
      left: 0;
      opacity: 1;
      top: 0;
      visibility: visible;
      width: 100%;
    }

    .historical-points_expanded {
      .historical-points__cover {
        visibility: hidden;
      }
    }
  }
}

@import 'src/styles/media-breakpoint';

.charts .crx-card {
  padding: 0 16px;
}

.symptoms-trend-header {
  display: flex;
  justify-content: space-between;

  &__info-message {
    color: #ff4d4f;
    padding-right: 5px;
  }
}

@include media-breakpoint(gt-md) {
  .charts .crx-card {
    padding: 0 20px;
  }
}

@import 'src/styles/media-breakpoint';

.crx-form-collapse {
  background-color: #f0f0f0;
  &__panel {
    .ant-collapse-header {
      background-color: #fafafa;
      border: 1px solid #d9d9d9;
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
      margin-bottom: 20px;
    }

    .ant-collapse-content-box {
      padding: 16px !important;
    }
  }
}

@include media-breakpoint(gt-md) {
  .crx-form-collapse {
    &__panel {
      .ant-collapse-header {
        background-color: inherit;
        border: none;
        border-bottom: 1px solid #d5d5d5;
        margin-bottom: 0;
      }
    }
  }
}

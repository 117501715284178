@import 'src/styles/variables';
@import 'src/styles/palette';
@import 'src/styles/media-breakpoint';

body {
    background: white;
    font-family: $basefont;
}
.outcome-progress-container {
    background: white;
    height: calc(100vh - 60px);
}
.outcome-content-container {
    font-family: Comfortaa;  
    padding: 0px 120px 10px 142px;

    .ant-progress-bg {
        // Use !important to override antd height
        height: 40px !important;
    }
}
.title-container {
    margin-bottom: 40px;
}

.font-brand {
    font-family: $basefont;   
}

.title-complete {
    font-size: 24px;
}
.days-left-row {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-family: Comfortaa;
}
.days-left-container {
    align-items: center;
    background: #f0f2f5;
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: center;
    padding: 24px 36px 12px 36px;
}
.days-left-days-left {
    background: transparent;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
}
.days-left-text {
    padding-left: 28px;
}

@import 'src/styles/palette';

.rating-slider {
  background: #ffffff;
  border: 1px solid #e4e6eb;
  border-radius: 100px;
  height: 36px;
  min-width: 529px;
  padding: 15px 14px 15px 15px;
  position: relative;

  &__bg {
    align-content: stretch;
    align-items: center;
    display: flex;
    height: 4px;
    justify-content: space-evenly;
    width: 100%;

    &-none,
    &-normal,
    &-mild,
    &-moderate,
    &-severe {
      flex: 1;
      height: 100%;
      margin: 0 1px;
    }

    &-none {
      background-color: color(background, dark);
    }

    &-normal {
      background-color: color(normal, light);
    }

    &-mild {
      background-color: color(mild, light);
    }

    &-moderate {
      background-color: color(moderate, light);
    }

    &-severe {
      background-color: color(severe, light);
    }
  }

  &__handle {
    background: no-repeat right 8px top 4px url('./dots.svg') #fff;
    border: 2px solid color(background, dark);
    border-radius: 100px;
    cursor: pointer;
    font-size: 14px;
    left: 20.7px;
    padding: 0 22px 0 8px;
    position: absolute;
    text-align: center;
    top: 4px;
    touch-action: none;
    width: 88px;

    &_normal {
      border-color: color(normal, light);
    }

    &_mild {
      border-color: color(mild, light);
    }

    &_moderate {
      border-color: color(moderate, light);
    }

    &_severe {
      border-color: color(severe, light);
    }
  }
}

.circular-rating {
  accent-color: red;
}

/*
  radio buttons should be 40x40 width height 
  32*32 inner color

.circular-rating {
  -ms-transform: scale(2.0);
  -webkit-transform: scale(2.0);
  transform: scale(2.0);
  background-color: red;  

  &:hover:after {
    background-color: red;
    height: 14px;
    width: 14px;
    border-radius: 7px;
    position: absolute;
    top: 11px;
    left: 1px;
  }

  &:checked {
    background-color: red;
  }
}

  &:checked:after {
    content:"";
    background-color: red;
    height: 14px;
    width: 14px;
    border-radius: 7px;
    position: absolute;
    top: 11px;
    left: 1px;
  }

  &:before {
    content: " ";
    display: inline-block;
    position: absolute;
    height: 16px;
    width: 16px;
    border-radius: 8px;
    background-color: blue;
    top: 10px;
  }
*/

/*
.ant-radio-inner:after {
  background: red !important;
}

.ant-radio-checked .ant-radio-inner,
.ant-radio:hover .ant-radio-inner,
.ant-radio-wrapper:hover,
.ant-radio-input:focus .ant-radio-inner {
  border-color: red !important;
}
input[type=radio] {
  accent-color: #d1d3d1;
}

  :after {
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
  }
*/

.historical-table {
  display: flex;
  flex-direction: column;
  height: 88px;
  justify-content: flex-start;

  &__row {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 22px;
    justify-content: space-between;
    padding: 0 12px;

    &:nth-child(odd) {
      background-color: #f0f2f5;
    }

    span {
      color: #242425;
      font-family: Rogan;
      font-size: 12px;
      font-style: normal;
      font-weight: normal;
      line-height: 14px;
      text-transform: uppercase;

      &:last-child {
        font-weight: 800;
      }
    }

    &:first-child {
      span {
        font-weight: 800;
      }
    }
  }
}

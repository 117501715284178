@import 'src/styles/palette';

.ant-avatar {
  background-color: color(primary);
}

.user-info {
  padding-left: 24px;

  &__name {
    font-size: 14px;
    font-weight: bold;
    margin-right: 16px;
  }
}

.gauge {
  background: url('/img/dots.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  &.expired {
    filter: grayscale(1);
  }
}

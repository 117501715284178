.new-item-input {
  display: flex;
  align-items: center;

  &__text {
    flex: 1;
    height: 42px;
  }

  &__accept {
    color: #61bb46;
  }

  &__cancel {
    color: #fb4d42;
  }
}

.addButton {
  width: 100%;
  padding: 10px 0;
  height: auto;
  border-radius: 2px;
}

@import 'src/styles/media-breakpoint';

.assessment-container {
  background-color: white;
  font-family: Comfortaa;

  &__hero {
    padding-left: 120px;
  }
}

.hero {
  &__container {
    font-family: Comfortaa;
    margin-bottom: 60px;
  }
  &__title {
    color: #242425;
    font-family: Comfortaa;
    font-size: 30px;
    line-height: 40px;
    padding-bottom: 18px;
  }
  &__description {
    font-family: Comfortaa;
    font-size: 14px;
    line-height: 23px;
  }
}

// TODO: Use 
@include media-breakpoint(gt-xs) {
  .hero {
    &__description {
      padding-right: 140px;
    }
  }
}

.assessment-steps-container {
  margin-bottom: 40px;
  padding-left: 120px;
}

.ready-button {
  background-color: #242425;
  border-radius: 110px;
  color: white;
  font-family: Comfortaa;
  font-size: 22px;
  height: 60px;
  width: 220px;
}

.checkbox-container {
  margin-left: 120px;

  &__label {
    color: #242425;
    font-family: Comfortaa;
    font-size: 14px;
    margin-bottom: 24px;
    margin-left: 10px;
  }
}

@import 'src/styles/palette';

.material-icons {
  display: inline-block;
  vertical-align: inherit;

  &.sm {
    font-size: 18px;
  }
  &.md {
    font-size: 24px;
  }
  &.lg {
    font-size: 36px;
  }
  &.xl {
    font-size: 48px;
  }

  &.md-dark {
    color: color(foreground);
    &.md-inactive {
      color: color(foreground, light);
    }
  }

  &.md-light {
    color: color(background, light);
    &.md-inactive {
      color: color(background, dark);
    }
  }
}

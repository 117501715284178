@import 'src/styles/palette';
@import 'src/styles/variables';

.crx-header {
  background-color: color(background);
  height: $header-height;
  line-height: $header-height;
  overflow: hidden;
  padding: 0;
  z-index: 500;

  .trigger {
    background-color: color(background, dark);
    border-radius: 0px 0px 6px 0px;
    cursor: pointer;
    font-size: 22px;
    line-height: $header-height;
    line-height: $header-height;
    padding: 0 17px;
    transition: color 0.3s;

    &:hover {
      color: color(primary, dark);
    }
  }
}

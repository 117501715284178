@import 'src/styles/palette';

.back-button {
  align-items: center;
  border: 1px solid color(background, dark);
  border-radius: 6px;
  display: flex;
  padding: 10px;

  &__icon {
    margin-right: 8px;
  }

  &:hover {
    background-color: color(background, dark);
  }
}

.goal-table {
  .ant-table {
    background: linear-gradient(98.28deg, #ffffff 0%, #f2f8f1 100%);
    border-radius: 8px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);

    table td:last-child {
      padding-left: 0;
    }

    table thead {
      > tr {
        th:first-child {
          border-top-left-radius: 8px;
        }

        > th:nth-child(2) {
          text-align: left;
        }

        > th {
          background-color: #d5ebcf;
          text-align: center;
        }

        > th:last-child {
          border-top-right-radius: 8px;
        }
      }
    }
  }

  .ant-table-footer {
    background: none;
  }

  .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
  }
}

@import 'src/styles/palette';

.treatment-card {
  background-color: white;
  border-radius: 10px;
  padding: 16px 42px 16px 42px;
}
.treatment-container {
  font-family: Comfortaa;

  max-width: 600px;
}

.label {
  font-size: Comfortaa;
  text-align: center;

  &__large {
    font-size: 34px;
    font-weight: 700;
    line-height: 20.07px;
  }
}

.treatment-categories {
  display: grid;
  font-family: Comfortaa;

  &__title {
    color: #5278d9;
  }

  &__large {
    font-size: 18px;
    font-weight: 700;
    line-height: 20.07px;
  }
}

.treatment-item {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-style: Comfortaa;

  &__name {
    margin-right: 8px;
  }
  &__img {
    vertical-align: middle;
  }
}

.recovery-card {
  background-color: white;
  border-radius: 10px;
  height: 300px;
  margin-bottom: 32px;
  padding: 16px 42px 16px 42px;
  text-align: center;
  width: 300px;
}

.treatment_content {
  color: #242425;
  font-size: 14px;
}
@import 'src/styles/media-breakpoint';

.crx-card {
  .symptom-card {
    position: relative;

    &__gauge {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 15px;

      h3 {
        font-size: 18px;
        height: 22px;
        line-height: 22px;
        text-align: center;
      }
    }

    .symptom-link {
      display: flex;
      opacity: 0;
      position: absolute;
      transition: visibility 0s, opacity 0.2s linear;
      visibility: hidden;
    }

    &:hover {
      .symptom-link {
        align-items: center;
        background-color: #5278d91a;
        cursor: pointer;
        height: 100%;
        justify-content: center;
        left: 0;
        opacity: 1;
        top: 0;
        visibility: visible;
        width: 100%;

        & > div {
          background: white;
          border-radius: 40px;
          box-shadow: 0 0 0 10px rgba(82, 120, 217, 0.65);
          color: black;
          font-size: 14px;
          font-weight: 700;
          margin-top: 40px;
          padding: 6px 10px;
        }
      }
    }
  }

  &_expanded {
    .symptom-card {
      &__gauge {
        align-items: flex-start;
        h3 {
          margin-bottom: 0;
        }
      }

      &:hover {
        .symptom-link {
          visibility: hidden;
          & > div {
            display: none;
          }
        }
      }
    }
  }

  @include media-breakpoint(xs) {
    .symptom-card {
      padding: 7px 10px;
    }
  }
}

.how-are-you-tracker {
  .crx-card + .crx-card {
    margin-top: 24px;
  }

  .ant-list-item {
    padding: 8px 24px;

    &:hover {
      background: #ffffff;
      border-color: transparent;
      box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.05),
        0px 10px 20px rgba(0, 0, 0, 0.05);
    }
    &:hover {
      border-color: transparent;
    }
  }

  .ant-card-body {
    padding: 0;
  }

  .ant-slider-step::before {
    background-color: #ffffff;
    border: 2px solid #61bb46;
    border-radius: 6px;
    content: ' ';
    display: inline-block;
    height: 12px;
    left: -8px;
    position: relative;
    top: -8px;
    width: 12px;
  }

  .ant-slider-step::after {
    background-color: #ffffff;
    border: 2px solid #dae1d6;
    border-radius: 6px;
    content: ' ';
    display: inline-block;
    height: 12px;
    margin-left: 100%;
    position: relative;
    right: 4px;
    top: -30px;
    width: 12px;
  }

  .ant-slider-handle {
    height: 18px;
    margin-top: -7px;
    width: 18px;
  }

  .ant-slider-rail {
    background-color: #eef0ed;
    height: 8px;
    top: 2px;
  }

  .ant-slider-track {
    background-image: linear-gradient(
      90deg,
      #61bb46 0%,
      #bdce55 20.83%,
      #ffdc5f 46.35%,
      #fa6d53 100%
    );
    height: 8px;
    top: 2px;
  }

  h2 {
    padding: 0 24px;
    padding-top: 24px;
  }

  &__done-button {
    height: 50px;
    margin-top: 24px;
  }
}

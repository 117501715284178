.symptom-card-2 {
  height: 22rem;
  transition: all 0.1s;
  &:hover {
    cursor: pointer;
    transform: translateY(-1px);
  }

  &.flipped {
    border: 1px solid #a366fc;
    box-shadow: 0px 6px 16px 0px #00000014;
  }
}

.layout-container {
  background-color: white;
  padding: 24px;
}

.questionnaire-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__image {
    height: 250px;
    margin-bottom: 30px;
    max-width: 920px;
    object-fit: contain;
  }
}

.questionnaire-content-text {
  align-items: center;
  color: #242425;
  display: flex;
  flex-direction: column;
  font-family: Comfortaa;
  justify-content: center;
  max-width: 920px;
  text-align: center;

  &__subtitle {
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 12px;
  }
  &__title {
    font-size: 36px;
    line-height: 53px;
    padding-bottom: 12px;
  }
  &__description {
    font-size: 16px;
    line-height: 23px;
    padding-bottom: 50px;
  }
}

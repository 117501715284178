@import 'src/styles/palette';
@import 'src/styles/variables';

.crx-sider {
  border-radius: 0px 20px 20px 0px;
  box-shadow: 10px 0px 20px rgba(0, 0, 0, 0.05);
  height: 100vh;
  position: sticky !important;
  top: 0 !important;
  z-index: 1000;

  .sign-in {
    padding: 0px;
  }

  .sign-in button {
    margin: 5px;
  }

  h2.logo {
    font-size: 22px;
    font-weight: 600;
    height: $header-height;
    letter-spacing: -0.01em;
    line-height: $header-height;
    margin: 5px 0px $header-logo-margin 0px;
    padding-left: 20px;
  }

  ul.ant-menu {
    border: none;

    li {
      font-weight: 700;
      height: 56px;
      line-height: 56px;
      margin: 0;

      &.ant-menu-item-selected:not(.nohighlight),
      &.ant-menu-item-selected:not(.nohighlight) a {
        color: color(primary, dark);
      }

      &.ant-menu-item-selected {
        background: color(primary, light);
      }
      &.ant-menu-item-selected.nohighlight {
        background: transparent;
        color: rgba(118, 118, 121, 1);
      }

      &::after {
        display: none;
      }

      &::before:not(.nohighlight) {
        border-right: 3px solid #5278d9;
        bottom: 0;
        content: '';
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        transform: scaleY(0.0001);
        transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
          opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
      }

      &.ant-menu-item-selected::before {
        opacity: 1;
        transform: scaleY(1);
        transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
          opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }
  }

  ul.ant-menu.ant-menu-inline-collapsed {
    padding: 0;
  }
}

.crx-sider.ant-layout-sider-collapsed .logo {
  transition: ease-out;
  visibility: hidden;
}

/*account for margin logo*/
.rx-sidemenu {
  border-radius: 0px 0px 20px 0px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - $header-height - $header-logo-margin);
  justify-content: space-between;
}

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap');

@function toRem($value) {
  $remValue: calc($value / 16) + rem;
  @return $remValue;
}

@font-face {
  font-family: 'Rogan';
  font-style: normal;
  font-weight: 400;
  src: local('Rogan Medium'), local('Rogan-Medium'),
    url('~fonts/Rogan-Medium.woff2') format('woff2'),
    url('~fonts/Rogan-Medium.woff') format('woff'),
    url('~fonts/Rogan-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Rogan';
  font-style: normal;
  font-weight: 600;
  src: local('Rogan SemiBold'), local('Rogan-SemiBold'),
    url('~fonts/Rogan-SemiBold.woff2') format('woff2'),
    url('~fonts/Rogan-SemiBold.woff') format('woff'),
    url('~fonts/Rogan-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Rogan';
  font-style: normal;
  font-weight: 700;
  src: url('~fonts/Rogan-Bold.eot');
  src: local('Rogan Bold'), local('Rogan-Bold'),
    url('~fonts/Rogan-Bold.eot?#iefix') format('embedded-opentype'),
    url('~fonts/Rogan-Bold.woff2') format('woff2'),
    url('~fonts/Rogan-Bold.woff') format('woff'),
    url('~fonts/Rogan-Bold.ttf') format('truetype');
}

.root {
  color: color(foreground);
  font-family: Comfortaa, Rogan, 'Source Sans Pro', sans-serif, monospace !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  line-height: 22px;

  h1,
  h2,
  h3,
  h4,
  .ant-page-header-heading-title {
    font-family: Comfortaa !important;
  }

  h1,
  .ant-page-header-heading-title {
    font-size: 2rem;
    font-weight: 600;
    line-height: 28px;
  }

  h2 {
    font-size: 2rem;
    font-weight: 400;
    line-height: 21.6px;
  }

  h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 19.2px;
  }

  h4 {
    font-size: 14px;
    font-weight: 700;
    line-height: 16.8px;
  }

  .crx-subtitle {
    color: #5a5e60;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 24px;
  }


  .crx-typography {
    font-size: inherit;
    
    &.h1 {
      font-size: toRem(36);
      // font-weight: 140;
      margin: 5 0;
    }

    &.h2 {
      font-size: toRem(26);
      // font-weight: 120;
      margin: 0;
    }

    &.h3 {
      font-size: toRem(18);
      // font-weight: 140;
      margin: 0;
    }

    &.button-big {
      font-size: toRem(20);
      // font-weight: auto;
      margin: 0;
    }

    &.button-small {
      font-size: toRem(14);
      // font-weight: auto;
      margin: 0;
    }

    &.body {
      &-normal, &-bold {
        font-size: toRem(16);
        // font-weight: 145;
        margin: 0;
      }
    }

    &.label {
      &-large {
        font-size: toRem(18);
        // font-weight: 140;
        margin: 0;
      }

      &-small {
        font-size: toRem(16);
        // font-weight: auto;
        margin: 0;
      }
    }

    &.small {
      &-normal {
        font-size: toRem(12);
        // font-weight: 140;
        margin: 0;
      }

      &-link {
        font-size: toRem(14);
        // font-weight: auto;
        margin: 0;
      }
    }

    &.material-icon {
      font-size: toRem(18);
      // font-weight: auto;
      margin: 0;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@include media-breakpoint(gt-md) {
  .root {
    h1:not(.firebaseui-title),
    .ant-page-header-heading-title {
      font-size: 32px;
      line-height: 38.4px;
    }

    h2 {
      font-size: 26px;
      line-height: 31px;
    }

    h3 {
      font-size: 18px;
      line-height: 22px;
    }

    h4 {
      font-size: 16px;
      line-height: 19.2px;
    }

    .crx-subtitle {
      font-size: 16px;
    }
  }
}
